import React, { useEffect, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useParams, Link } from "react-router-dom";
import "./UnityLoader.css"; // Import the CSS file
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../translations/en/common.json";
import common_de from "../../translations/de/common.json";
import common_nl from "../../translations/nl/common.json";
import i18n from "i18next";

// Initialize i18n for translations
i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: { common: common_en },
    de: { common: common_de },
    nl: { common: common_nl },
  },
});

const UnityLoader = () => {
  const { t } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(true);
  const [queuedMessage, setQueuedMessage] = useState(null);
  const [ellipsis, setEllipsis] = useState("...");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo ? userInfo.token : null;
  const { id } = useParams();

  const { unityProvider, isLoaded, sendMessage, requestFullscreen } = useUnityContext({
    loaderUrl: "/unity/Build/unity.loader.js",
    dataUrl: "/unity/Build/unity.data.unityweb",
    frameworkUrl: "/unity/Build/unity.framework.js.unityweb",
    codeUrl: "/unity/Build/unity.wasm.unityweb",
  });

  // Update ellipsis animation
  useEffect(() => {
    const interval = setInterval(() => {
      setEllipsis((prev) => (prev.length < 3 ? prev + "." : ""));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      setIsLoading(false);
      if (queuedMessage) {
        const { objectName, methodName, message } = queuedMessage;
        sendMessage(objectName, methodName, message);
        setQueuedMessage(null);
      }
    }
  }, [isLoaded, sendMessage, queuedMessage]);

  useEffect(() => {
    if (token && id) {
      const data = `${token},${id}`;
      if (isLoaded) {
        sendMessage("APIManager", "LoadScenario", data);
      } else {
        setQueuedMessage({ objectName: "APIManager", methodName: "LoadScenario", message: data });
      }
    }
  }, [token, id, isLoaded, sendMessage]);
  

  const handleFullscreen = async () => {
    try {
      const { innerWidth, innerHeight } = window;
      console.log(`Client resolution: ${innerWidth}x${innerHeight}`);
      await requestFullscreen(true);

      if (screen.orientation && screen.orientation.lock) {
        await screen.orientation.lock("landscape");
      } else {
        console.warn("Screen orientation lock not supported on this device.");
      }
    } catch (error) {
      console.error("Error making fullscreen or locking orientation:", error);
    }
  };

  useEffect(() => {
    // Register the exitFullscreen function globally for Unity
    window.exitFullscreen = () => {
      console.log("Exiting fullscreen...");
      document.exitFullscreen?.();
    };
  
    // Cleanup on unmount
    return () => {
      delete window.exitFullscreen;
    };
  }, []);

  return (
    <div className="container-fluid loaded" style={{ display: "block" }}>
      <div>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("ADMIN_HOME")}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`/scenarios/edit/${id}`}>{t("Scenarios")}</Link>
                  </li>
                  <li className="breadcrumb-item active">{t("Generate 3D Scenario")}</li>
                </ol>
              </div>
              <h4 className="page-title">{t("3D Scenarios")}</h4>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="unity-frame-container">
              {isLoading && (
                <div className="unity-loading-container">
                  <p className="unity-loading-message">
                    {t("Generating 3D scenario, please wait")}{ellipsis}
                  </p>
                  <p className="unity-downloading-message">
                    {t("Downloading necessary data. This may take time depending on your internet connection.")}
                  </p>
                </div>
              )}
              <Unity unityProvider={unityProvider} className="unity-element" />
            </div>
            <div className="unity-fullscreen-container">
              <button onClick={handleFullscreen} className="unity-fullscreen-button">
                {t("Full Screen")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnityLoader;
