import React, { useState, useEffect, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import FileDownload from 'js-file-download'
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import common_en from "../../translations/en/common.json"
import common_de from "../../translations/de/common.json"
import common_nl from "../../translations/nl/common.json"
import { useDispatch } from "react-redux";
import { loginStatus } from "../../actions/userActions";
import { Modal } from "react-bootstrap";
import { Bars } from "react-loader-spinner";
import SelectWithErrors from "../../components/Basic/Select/SelectWithErrors";
import footballField from "../../data/pitch.png"
import "./canvas.css"

i18n
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    resources: {
      en: {
        common: common_en
      },
      de: {
        common: common_de
      },
      nl: {
        common: common_nl
      }
    },
  });

var pp = false
let listTeamA = []
let listTeamB = []

const EditScenarios = () => {
  const { t } = useTranslation('common')
  const { id } = useParams()
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const uid = userInfo !== null ? userInfo.user_id : null
  const token = userInfo !== null ? userInfo.token : null
  const history = useHistory()
  const dispatch = useDispatch()

  const [form, setForm] = useState({
    name: "",
    folder_id: 30,
    favorite: false,
    source: "",
    scenarioType: "",
    startTime: "",
    endTime: "",
    description: "",
    tags: null
  });

  const [folders, setFolders] = useState([]);
  const [scenario, setScenario] = useState();
  const [tags, setTags] = useState([]);
  const [ea5File, setEa5File] = useState();
  const [imageFile, setImageFile] = useState()
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [sourceFile, setSourceFile] = useState()
  const [metaFile, setMetaFile] = useState()
  const [eventFile, setEventFile] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [showEditScenario, setShowEditScenario] = useState(false)
  const [teamA, setTeamA] = useState({})
  const [teamB, setTeamB] = useState({})
  const [ball, setBall] = useState({})
  const [frameNumber, setFrameNumber] = useState(0)
  const [frameLength, setFrameLength] = useState([])
  const [pause, setPause] = useState(false)
  const [activeFrameButton, setActiveFrameButton] = useState({ 'button_0': true })
  const [length, setLength] = useState(56)
  const [henti, setHenti] = useState(false)
  const [stageHeight, setStageHeight] = useState(0)
  const [stageWidth, setStageWidth] = useState(0)
  const [isDrag, setIsDrag] = useState(false)
  let parentFolder = []

  const [timeStamps, setTimeStamps] = useState({ minutes: 0, second: 0 })
  const [startTime, setStartTime] = useState({ minutes: "", seconds: "" })
  const [endTime, setEndTime] = useState({ minutes: "", seconds: "" })

  const configJson = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }

  const uploadFile = async (e) => {
    if (e.target.files && e.target.files.length === 1) {
      if (e.target.name === "meta") {
        setMetaFile(e.target.files[0])
      } else if (e.target.name === "event") {
        setEventFile(e.target.files[0])
      } else {
        if (form.source === "saivavt") {
          getSaivaVTLength(e)
        } else if (form.source === "ea5") {
          getEa5Length(e)
        } else if (form.source === "metrica") {
          getMetricaLength(e)
        }
        setSourceFile(e.target.files[0])
      }
    }
  }

  const getEa5Length = (e) => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = (e.target.result)
      const fileLength = JSON.parse(text).sceneCount
      setTimeStamps({ minutes: padTo2Digits(Math.floor(fileLength / 60)), second: padTo2Digits(fileLength % 60) })
    }
    reader.readAsText(e.target.files[0])
  }

  const getMetricaLength = (e) => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = (e.target.result)
      const rowCount = text.split('\n')
      const fileLength = Math.round((rowCount.length - 1) / 25)
      setTimeStamps({ minutes: padTo2Digits(Math.floor(fileLength / 60)), second: padTo2Digits(fileLength % 60) })
    };
    reader.readAsText(e.target.files[0])
  }

  const getSaivaVTLength = (e) => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = (e.target.result)
      const fileLength = (JSON.parse(text).Players[0].maxFrameNumber) / 5
      setTimeStamps({ minutes: padTo2Digits(Math.floor(fileLength / 60)), second: padTo2Digits(fileLength % 60) })
    };
    reader.readAsText(e.target.files[0])
  }

  const uploadImage = async (e) => {
    if (e.target.files && e.target.files.length === 1) {
      setImageFile(e.target.files[0])
    }
  }

  useEffect(() => {
    const getFolders = async () => {
      try {
        const folders = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/folder/user/${uid}`,
          configJson
        );

        folders.data.map(folder => {
          if (folder.parent_id === 0) {
            parentFolder.push(folder)
          }
        })

        folders.data.map(folder => {
          if (folder.parent_id !== 0) {
            parentFolder.map(parent => {
              if (parent.folder_id === folder.parent_id) {
                folder.name = `${parent.name}/${folder.name}`
                return folder
              }
            })
          } else {
            return folder
          }
        })
        setFolders(folders.data);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(loginStatus())
        }
      }
    };

    const getScenario = async () => {
      const scenario = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/${id}`, configJson)
      setForm(scenario.data)
      scenario.data.tags ? setTags(scenario.data.tags.split(",")) : ""
      setStartTime({ minutes: padTo2Digits(scenario.data.startTime === "NaN" ? 0 : Math.floor(parseInt(scenario.data.startTime) / 60)), seconds: padTo2Digits(scenario.data.startTime === "NaN" ? 0 : parseInt(scenario.data.startTime) % 60) })
      setEndTime({ minutes: padTo2Digits(scenario.data.startTime === "NaN" ? 0 : Math.floor(parseInt(scenario.data.endTime) / 60)), seconds: padTo2Digits(scenario.data.endTime === "NaN" ? 0 : parseInt(scenario.data.endTime) % 60) })
    }
    getFolders();
    getScenario()
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData()
    formData.append('image', imageFile)
    formData.append('name', form.name)
    formData.append('folder_id', form.folder_id)
    formData.append('favorite', form.favorite)
    formData.append('source', form.source)
    formData.append('scenarioType', form.scenarioType)
    formData.append('description', form.description)
    formData.append('tags', tags.toString())

    const config = {
      headers: {
        'Content-Type': 'multiparf/form-data',
        Authorization: `Bearer ${token}`
      }
    }

    try {
      setIsLoading(true)
      const scenario = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/${id}`, formData, config)
      setIsLoading(false)
      history.push('/scenarios')
    } catch (error) {
      setIsLoading(false)
      setErrors(error.response.data)
    }
  };

  const onChange = (e) => {
    if (e.target.name === "favorite") {
      setForm({ ...form, favorite: !form.favorite });
      return
    }

    if (e.target.value === "saivavt" || e.target.value === 'metrica') {
      setForm({ ...form, [e.target.name]: e.target.value, scenarioType: 'match' });
      return
    }

    if (e.target.name !== "scenarioType" && e.target.value === "ea5") {
      setForm({ ...form, [e.target.name]: e.target.value, scenarioType: '0' });
      return
    }

    setForm({ ...form, [e.target.name]: e.target.value })
  };

  const downloadFile = async (type) => {
    await axios(
      {
        url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/download/${id}?type=${type}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        method: 'GET',
        data: type,
        responseType: 'blob'

      }).then(res => {
        if (type === 'process') {
          FileDownload(res.data, `${form.name}_process.ea5`)
        } else if (type === 'match') {
          FileDownload(res.data, `${form.name}.json`)
        } else {
          FileDownload(res.data, `${form.name}.ea5`)
        }
      })
  }

  const addTags = (e) => {
    e.persist();
    if (e.key === "Enter") {
      if (tags.indexOf(e.target.value) === -1) {
        (async () => {
          await setTags((arr) => [...arr, e.target.value]);
          e.target.value = null
        })()
      } else {
        e.target.value = null
      }
    }
  };

  const removeTag = (index) => {
    const arr = [...tags]
    arr.splice(index, 1)
    setTags(arr)
  }

  const deleteScenarioConfirmation = async () => {
    setDeleteConfirmation(true)
  }

  const deleteScenario = async () => {
    const scenario = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/${id}`, configJson)
    history.push('/scenarios')
  }

  Fancybox.bind("[data-fancybox]", {

  });

  const reverseConvert = (x, y, dim_x, dim_y) => {
    const xmin_A = 52.5
    const xmax_A = -52.5
    const ymin_A = -34
    const ymax_A = 34

    const xmin_B = 0
    const xmax_B = dim_x
    const ymin_B = dim_y
    const ymax_B = 0

    const x_range_A = xmax_A - xmin_A
    const x_range_B = xmax_B - xmin_B

    //const converted_x = ((x - xmin_A) / x_range_A) * x_range_B + xmin_B
    const converted_x = (((x - xmin_B) / x_range_B) * x_range_A) + xmin_A

    const y_range_A = ymax_A - ymin_A
    const y_range_B = ymax_B - ymin_B

    //const converted_y = ((y - ymin_A) / y_range_A) * y_range_B + ymin_B
    const converted_y = (((y - ymin_B) / y_range_B) * y_range_A) + ymin_A

    return {
      x: converted_x,
      y: converted_y
    }
  }

  const convert_coordinate = (x, y, dim_x, dim_y) => {
    const xmin_A = 52.5
    const xmax_A = -52.5
    const ymin_A = -34
    const ymax_A = 34

    const xmin_B = 0
    const xmax_B = dim_x
    const ymin_B = dim_y
    const ymax_B = 0

    const x_range_A = xmax_A - xmin_A
    const x_range_B = xmax_B - xmin_B

    const converted_x = ((x - xmin_A) / x_range_A) * x_range_B + xmin_B

    const y_range_A = ymax_A - ymin_A
    const y_range_B = ymax_B - ymin_B

    const converted_y = ((y - ymin_A) / y_range_A) * y_range_B + ymin_B

    return {
      x: converted_x,
      y: converted_y
    }
  }

  const editScenario = async () => {
    document.querySelector('.canvas').style.display = "block"
    document.querySelector('.canvas').style.opacity = 1
    const scenarios = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/get?id=${id}`, configJson)

    const tempA = scenarios.data.sceneObjects.filter(obj => {
      if (obj.team) {
        return obj.team === 2
      }

      if (obj.shirt) {
        return obj.shirt === 2
      }

    })

    const tempB = scenarios.data.sceneObjects.filter(obj => {
      if (obj.team) {
        return obj.team === 1
      }

      if (obj.shirt) {
        return obj.shirt === 1
      }

    })

    const ball = scenarios.data.sceneObjects.filter(obj => {
      return obj.id === 'ball'
    })

    setBall(ball)
    setFrameLength(Array(scenarios.data.info.sceneCount).fill(0))
    setTeamB(tempB)
    setTeamA(tempA)

    setShowEditScenario(true)
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }



  // Canvas Konva
  var width = 1060
  var height = 706

  let stage

  var layer = new Konva.Layer()

  useEffect(() => {
    stage = new Konva.Stage({
      container: 'konva-container',
      width: width,
      height: height,
    });

    listTeamA = []
    listTeamB = []

    setStageHeight(stage.height())
    setStageWidth(stage.width())

    // stage.container = 'konva-container'
    // stage.width = width
    // stage.height = height

    // Image API
    // var imageObj = new Image()
    // imageObj.onload = function () {
    //   var football = new Konva.Image({
    //     x: 0,
    //     y: 0,
    //     image: imageObj,
    //     width,
    //     height
    //   })

    //   layer.add(football)
    //   football.zIndex(0)
    // }
    // imageObj.src = footballField

    if (pause) { setHenti(true) }

    if (!_.isEmpty(teamA)) {
      let indexa = 0;

      teamA.map(team => {
        const converted = convert_coordinate(team.transitions[frameNumber].position.y, team.transitions[frameNumber].position.x, stage.width(), stage.height())

        window["teama_" + (indexa + 1)] = new Konva.Circle({
          x: converted.x,
          y: converted.y,
          radius: 7,
          fill: 'red',
          name: "teama_" + team,
          draggable: true,
          id: team.id,
          team: team.team
        });

        window["teamatext_" + (indexa + 1)] = new Konva.Text({
          x: converted.x,
          y: converted.y,
          text: "Indexa_" + indexa
        });

        listTeamA.push(window["teama_" + (indexa + 1)])
        layer.add(window["teama_" + (indexa + 1)])
        // layer.add(window["teamatext_" + (indexa + 1)])

        indexa++;
      })

      let indexb = 0

      teamB.map(team => {
        const converted = convert_coordinate(team.transitions[frameNumber].position.y, team.transitions[frameNumber].position.x, stage.width(), stage.height())
        window["teamb_" + (indexb + 1)] = new Konva.Circle({
          x: converted.x,
          y: converted.y,
          radius: 7,
          fill: 'blue',
          name: 'teamb_' + team,
          draggable: true,
          id: team.id,
          team: team.team
        });
        listTeamB.push(window['teamb_' + (indexb + 1)])
        layer.add(window["teamb_" + (indexb + 1)])

        indexb++;
      })

      const ball_converted = convert_coordinate(ball[0].transitions[frameNumber].position.y, ball[0].transitions[frameNumber].position.x, stage.width(), stage.height())

      window["ballTeam"] = new Konva.Circle({
        x: ball_converted.x,
        y: ball_converted.y,
        radius: 5,
        fill: 'white',
        name: 'ball',
        draggable: true
      })
      layer.add(ballTeam)
    }
    fitStage()
    stage.add(layer)

  }, [teamA, teamB, frameNumber, isDrag])



  const fitStage = () => {
    var container = document.querySelector('#stage-parent');

    if (container) {
      var containerWidth = container.offsetWidth;

      if (containerWidth === 0) {
        containerWidth = window.innerWidth / 1.45
      }

      var scale = containerWidth / width;

      stage.width(width * scale);
      stage.height(height * scale);
      stage.scale({ x: scale, y: scale });
    }
  }

  useEffect(() => {
    fitStage()
    window.addEventListener('resize', fitStage)
  }, [teamA, teamB])

  const changeFrameNumber = (number, e) => {
    e.preventDefault()
    setActiveFrameButton({ [`button_${number}`]: true })
    setFrameNumber(number)
  }

  const pauseScenario = () => {
    pp = true
    setPause(true)
  }

  const [play, setPlay] = useState(false)

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const playScenario = async () => {
    let i = frameNumber

    pp = false

    while (i < frameLength.length && pp === false) {
      setActiveFrameButton({ [`button_${i}`]: true })
      setFrameNumber(i)
      await sleep(200)
      i++
    }

    if (i === 56) {
      setFrameNumber(0)
      setActiveFrameButton({ [`button_0`]: true })
    }
  }

  const getPosition = async () => {
    const iteam = listTeamA.map(item => {
      console.log(item.attrs.x)
      const converted = reverseConvert(item.attrs.x, item.attrs.y, stageWidth, stageHeight)
      return {
        x: converted.x,
        y: converted.y,
        id: item.attrs.id,
        team: item.attrs.team
      }
    })

    const itebm = listTeamB.map(item => {
      const converted = reverseConvert(item.attrs.x, item.attrs.y, stageWidth, stageHeight)
      return {
        x: converted.x,
        y: converted.y,
        id: item.attrs.id,
        team: item.attrs.team
      }
    })

    const ball = {
      x: window["ballTeam"].attrs.x,
      y: window["ballTeam"].attrs.y,
    }

    const res = {
      scenario_id: id,
      frame: frameNumber,
      teamA: iteam,
      teamB: itebm,
      ball,
    }

    const edit = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/scenarios/edit/${id}`, res, configJson)

    window.location.reload()
  }

  return (
    <div className="container-fluid loaded" style={{ display: "block", position: "relative" }}>
      <div style={{ display: isLoading ? "block" : "none", position: "absolute", top: "29rem", left: "51.4rem", zIndex: 1, color: "#60a5fa" }}>
        <Bars
          height="80"
          width="80"
          color="#2563eb"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        <p style={{ textAlign: "center", fontSize: "20px" }}>Processing....</p>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{t('ADMIN_HOME')}</Link>
                </li>
                <li className="breadcrumb-item active">{t('Scenarios')}</li>
              </ol>
            </div>
            <h4 className="page-title">{t('Scenarios')}</h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <fieldset>
                <div style={{ width: "100%" }} id="parent"></div>
                <legend>{t('Edit scenario')}</legend>
                <div className="form-group row">
                  <label className="col-3 col-form-label">{t('Name')}:</label>{" "}
                  <div className="col-8">
                    <input
                      className="form-control"
                      maxLength="64"
                      name="name"
                      onChange={onChange}
                      type="text"
                      value={form.name}
                      placeholder="Scenario name..."
                      style={{ border: errors.length > 0 ? errors.some(val => { return val.path[0] === "name" }) ? "1px red solid" : "" : "" }}
                    />
                    {errors.length > 0 ? errors.some((val) => { return val.path[0] === "name" }) && <p class="mt-1" style={{ color: "red", fontWeight: 600, fontSize: "14px" }}><i>{errors.find(val => val.path[0] === "name").message}</i></p> : ""}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3 col-form-label">{t('Folder')}:</label>
                  <div className="col-8">
                    <select
                      className="custom-select"
                      name="folder_id"
                      onChange={onChange}
                      value={form.folder_id}
                      style={{ border: errors.length > 0 ? errors.some(val => { return val.path[0] === "folder_id" }) ? "1px red solid" : "" : "" }}
                    >
                      <option value="0">{t('Root folder')}</option>
                      {folders &&
                        folders.map((folder) => (
                          <option key={folder.folder_id} value={folder.folder_id}>
                            {folder.name}
                          </option>
                        ))}
                    </select>
                    {errors.length > 0 ? errors.some((val) => { return val.path[0] === "folder_id" }) && <p class="mt-1" style={{ color: "red", fontWeight: 600, fontSize: "14px" }}><i>{errors.find(val => val.path[0] === "folder_id").message}</i></p> : ""}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3 col-form-label">{('Favorite')}:</label>
                  <div className="col-8">
                    <input
                      className="form-control"
                      type="checkbox"
                      name="favorite"
                      checked={form.favorite}
                      onChange={onChange}
                      value={form.favorite}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3 col-form-label">{t('Description')}:</label>
                  <div className="col-8">
                    <textarea
                      className="form-control"
                      cols="50"
                      rows="4"
                      name="description"
                      onChange={onChange}
                      value={form.description}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3 col-form-label">{t('Tags')}:</label>
                  <div className="col-8">
                    <div className="form-control bootstrap-tagsinput">
                      {tags.map((sc, index) => (
                        <span key={index} style={{ marginLeft: "5px" }} className="tag badge badge-info">
                          {sc}
                          <button onClick={() => removeTag(index)} type="button" style={{ fontWeight: 700, fontSize: "13px", border: "none", backgroundColor: "transparent", color: "white" }}>x</button>
                        </span>
                      ))}
                      <input onKeyDown={addTags} name="addTags" style={{ border: "none", outline: "none" }} type="text" className="allow-enter" placeholder="" />
                    </div>
                    <input
                      data-role="tagsinput"
                      name="tags"
                      type="text"
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
                <div className="form-group row scenario-type">
                  <label htmlFor="" className="first-label col-3 col-form-label">Source</label>
                  <div className="content col-8">
                    <SelectWithErrors
                      name="source"
                      value={form.source}
                      errors={errors}
                      data={
                        [
                          { "id": "saivavt", "name": "Video" },
                          { "id": "ea5", "name": "Easy Animation" },
                          { "id": "sportec", "name": "Sportec" }
                        ]
                      }
                      keyPair={{ key: "id", val: "name" }}
                      onChange={onChange}
                      className="form-control source col-4"
                      intialSelect={{ key: "0", val: "--- Please Select Source Type ---" }}
                      style={{ fontSize: "0.8rem" }}
                      errorStyle={{ fontSize: "0.76rem" }}
                      disabled
                    />
                    <div className="type form-group row col-8">
                      <label htmlFor="" className="col-4 col-form-label">Scenario Type</label>
                      <div className="type-content col-8">
                        <SelectWithErrors
                          name="scenarioType"
                          value={form.scenarioType}
                          errors={errors}
                          data={[{ "id": "match", "name": "Match" }, { "id": "training", "name": "Training" }]}
                          keyPair={{ key: "id", val: "name" }}
                          onChange={onChange}
                          className="form-control"
                          intialSelect={{ key: "0", val: "--- Please Select Scenario Type ---" }}
                          style={{ fontSize: "0.8rem" }}
                          errorStyle={{ fontSize: "0.76rem" }}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="type-small form-group row">
                  <label htmlFor="" className="col-3 col-form-label">Scenario Type</label>
                  <div className="type-content col-8">
                    <SelectWithErrors
                      name="scenarioType"
                      value={form.scenarioType}
                      errors={errors}
                      data={[{ "id": "match", "name": "Match" }, { "id": "training", "name": "Training" }]}
                      keyPair={{ key: "id", val: "name" }}
                      onChange={onChange}
                      className="form-control"
                      intialSelect={{ key: "0", val: "--- Please Select Scenario Type ---" }}
                      style={{ fontSize: "0.8rem" }}
                      errorStyle={{ fontSize: "0.76rem" }}
                      disabled={form.source === "ea5" ? false : true}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3 col-form-label">
                    Excercise {t('Image')}:
                  </label>
                  <div className="col-8">
                    <input onChange={uploadImage} type="file" name="image" accept="image/png, image/jpeg " />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-3 col-form-label">
                    Excercise Picture {t('Image')}:
                  </label>
                  <div className="col-8">
                    {form.exerciseImage !== undefined && <img width={form.exerciseImage === 'no_image.png' ? '150px' : '350px'} src={`${process.env.REACT_APP_BACKEND_URL}/images/exercise/${form.exerciseImage}`} />}
                  </div>
                </div>

                <div className="form-messages mt-2"></div>
              </fieldset>
              <button type="button" onClick={onSubmit} className="btn button">
                <i className="fa fa-fw fa-floppy-o"></i> Save
              </button>
              <Link
                to="/scenarios"
                className="button-cancel"
              >
                <i className="fa fa-fw fa-ban"></i>&nbsp;Cancel
              </Link>
              {/* <button onClick={() => editScenario()} type="button" class="button btn-sm color-orange-bg"><i class="fa fa-fw fa-pencil pencil-alt"></i>&nbsp;Edit</button> */}
              <Link className="button btn-sm color-orange-bg" to={`/scenarios/edit/editor/${id}`}><i class="fa fa-fw fa-eye"></i>&nbsp;View Scenario</Link>
              <Link className="button btn-sm color-teal-bg" to={`/scenarios/edit/unity/${id}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon-unity">
                      <path d="M21 16V8L12 2 3 8v8l9 6 9-6z"></path>
                      <path d="M3.27 6.96l8.73 5.02 8.73-5.02"></path>
                      <path d="M12 22V12"></path>
                  </svg>
                  &nbsp;Generate 3D Scenario
              </Link>
              <button
                onClick={deleteScenarioConfirmation}
                className="btn button button-cancel button-right"
              >
                <i className="fa fa-fw fa-trash-o"></i> Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: showEditScenario ? "block" : "none" }} className="canvas">
        <div className="button">
          <button className="closeButton"><span onClick={() => setShowEditScenario(false)} style={{ fontWeight: "bold", fontSize: "20px" }}>x</span></button>
        </div>
        <div style={{ width: "100%", marginBottom: "5px" }} id="stage-parent">
          <div id="konva-container"></div>
        </div>
        <div style={{ width: "100%", marginBottom: "5px", gap: "10px" }} className="button-player">
          <button onClick={playScenario} className="btn btn-success"><i className="fa fa-play"></i> Play</button>
          <button onClick={pauseScenario} className="btn btn-warning"><i className="fa fa-pause"></i> Pause</button>
          <button className="btn btn-danger"><i className="fa fa-stop"></i> Stop</button>
          <button style={{ width: "150px" }} onClick={getPosition} className="btn btn-info float-right"><i className="fa fa-download"></i>Edit Scenario</button>
        </div>
        <ul>
          {frameLength.length > 0 && frameLength.map((item, index) => (
            <li><a style={{ background: activeFrameButton[`button_${index}`] ? "blue" : "", color: activeFrameButton[`button_${index}`] ? "white" : "" }} onClick={(e) => changeFrameNumber(index, e)} href="">{index + 1}</a></li>
          ))}
        </ul>
      </div>
      <Modal
        size="md"
        show={deleteConfirmation}
        onHide={() => setDeleteConfirmation(false)}
      >
        <Modal.Header closeButton>
          <h4>Scenario</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="row p-2">
            <div className="col-12">
              <h4 className="">Are you sure want to delete this Scenario ?</h4>
            </div>

          </div>
          <div className="row p-2">
            <button onClick={deleteScenario} className="btn btn-sm btn-success mr-2">Confirm</button>
            <button onClick={() => setDeleteConfirmation(false)} className="btn btn-sm btn-danger">Cancel</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditScenarios;
